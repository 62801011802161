import dayjs from 'dayjs/esm/index.js'
import ru from 'dayjs/esm/locale/ru'
import utc from 'dayjs/esm/plugin/utc/index.js'
import timezone from 'dayjs/esm/plugin/timezone/index.js'

dayjs.extend(utc)
dayjs.extend(timezone)
dayjs.tz.setDefault('Europe/Moscow')

export function formatEventDuration(dates: Date[]) {
  if (dates.length === 0) {
    return ''
  }

  const today = dayjs().startOf('day')
  const startDate = dayjs(dates[0]).tz('Europe/Moscow').locale('ru')
  const endDate = dayjs(dates[dates.length - 1])
    .tz('Europe/Moscow')
    .locale('ru')
  const startMonth = startDate.month()
  const endMonth = endDate.month()

  const uniqueDates = [...new Set(dates.map((date) => dayjs(date).format('YYYY-MM-DD')))].sort()
  const isDateInRange =
    (today.isAfter(startDate) || today.isSame(startDate, 'day')) &&
    (today.isBefore(endDate) || today.isSame(endDate, 'day'))
    
  // Если это одна дата или все даты в один день
  if (uniqueDates.length === 1) {
    return startDate.format('D MMMM')
  }

  if (startMonth === endMonth) {
    const isSequential = uniqueDates.every((date, index) => {
      if (index === 0) return true
      const curr = dayjs(date)
      const prev = dayjs(uniqueDates[index - 1])
      const diffDays = curr.diff(prev, 'day')
      return diffDays <= 1
    })

    if (isSequential) {
      if (isDateInRange) {
        return `до ${endDate.format('D MMMM')}`
      }
      return `${startDate.format('D')} – ${endDate.format('D MMMM')}`
    } else {
      return startDate.format('MMMM')
    }
  } else if (endMonth - startMonth <= 1) {
    if (isDateInRange) {
      return `до ${endDate.format('D MMMM')}`
    }
    return `${startDate.format('D MMMM')} – ${endDate.format('D MMMM')}`
  } else {
    if (isDateInRange) {
      return `до ${endDate.format('MMMM')}`
    }
    return `${startDate.format('MMMM')} – ${endDate.format('MMMM')}`
  }
}
